import React, { useState, useEffect } from "react";
import { Grid, Card, CardHeader, CardContent, TextField, Button, Typography, Box,useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';


import Loading from "./loading";
import ind from "./indicator.1d1b1f82.png";
import exe from "./ceo.4bdd1141.jpg";


import "./Dashboard.css";  // Import the CSS file
import "./errorSuppress.css";  // Import the CSS file


const DashboardCards = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [executivePassword, setExecutivePassword] = useState('');
  const [indicatorPassword, setIndicatorPassword] = useState('');
  const navigate = useNavigate();



  const handleExecutiveLogin = () => {
    // In a real application, you would validate the password securely
    if (executivePassword === 'executive123') {
      navigate('/app/executive-dashboard');
    } else {
      alert('Invalid executive password');
    }
  };

  const handleIndicatorLogin = () => {
    // In a real application, you would validate the password securely
    if (indicatorPassword === 'indicator123') {
      navigate('/app/indicator-dashboard');
    } else {
      alert('Invalid indicator password');
    }
  };

  return (
    <Grid container spacing={isMobile ? 1 : 2}>
      <Grid item xs={12} md={6} className="box" >
        <Card className="dashboard-card">
          <CardHeader title="EXECUTIVE DASHBOARD" />
          <CardContent style={{ backgroundImage: 'url("http://analytics-dev.mc-test.org/static/media/ceo.4bdd1141.jpg")' }}>
            <Box>
              <Typography className="dashboard-typography" >
                 Daily Streams<br />
                 Discovery Hub<br />
                <Typography variant="caption" sx={{ pl: isMobile ? 2 : 4, color: 'white' }}>
                  [The Miner]
                </Typography>
              </Typography>
            </Box>
            <Box className="login-section">
              <TextField
                type="password"
                value={executivePassword}
                onChange={(e) => setExecutivePassword(e.target.value)}
                placeholder="Enter executive password"
                variant="outlined"
                size={isMobile ? "small" : "medium"}
                className="login-input"
              /><br />
              <Button variant="contained" color="primary" size={isMobile ? "small" : "medium"}  onClick={handleExecutiveLogin}>
                LOG IN
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} className="box1" >
        <Card className="dashboard-card">
          <CardHeader title="INDICATOR DASHBOARD" />
          <CardContent style={{ backgroundImage: 'url("http://analytics-dev.mc-test.org/static/media/indicator.1d1b1f82.png")' }}>
            <Box>
              <Typography className="dashboard-typography1">
                 Daily Analytics By Track<br />
                 Weekly Analytics By Track<br />
                 Monthly Analytics By Track
              </Typography>
            </Box>
            <Box className="login-section">
              <TextField
                  type="password"
                  value={indicatorPassword}
                  onChange={(e) => setIndicatorPassword(e.target.value)}
                  placeholder="Enter indicator password"
                  variant="outlined"
                  size={isMobile ? "small" : "medium"}
                  className="login-input"
                /><br />
              <Button variant="contained" color="primary" size={isMobile ? "small" : "medium"}  onClick={handleIndicatorLogin}>
                LOG IN
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DashboardCards;



