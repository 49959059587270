import React, { useState, useEffect, useRef } from 'react';
import './ChatBotTest.css';
import axios from 'axios';

// Import Material-UI icons
import SendIcon from '@mui/icons-material/Send';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import BarChartIcon from '@mui/icons-material/BarChart'; // Import chart icon
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';

// Import your profile image
import profileImage from './analyst.png'; // Update with the correct path to your image
import profileImage1 from './ai-light.svg';

const ChatBot = () => {
  const navigate = useNavigate(); // Initialize useHistory
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const chatBoxRef = useRef(null); // Create a ref for the chatbox

  const handleChartClick = () => {
    navigate('/app/aianalyst'); // Redirect on click
  };

  const handleSend = async () => {
    if (input.trim()) {
      const newMessages = [...messages, { sender: 'user', text: input, favorite: false }];
      setMessages(newMessages);
      setInput('');
      setLoading(true);

      try {
        const botResponse = await generateBotResponse(input); // Use await here
        setMessages([...newMessages, { sender: 'bot', text: botResponse, favorite: false }]);
      } catch (error) {
        console.error("Error sending message:", error);
        // Optionally handle error (e.g., set an error message)
      } finally {
        setLoading(false);
      }
    }
  };

  const generateBotResponse = async (userInput) => {
    try {
      const response = await axios.post('https://analytics.mc-test.org/vertex_admin.php', { text: userInput });
      let message = response.data.messages[0];

        // Use regular expressions to replace patterns for bold formatting
        message = message.replace(/(\*\*)(.*?)(\*\*)/g, '<strong>$2</strong>'); // Replace markdown-like **text** with <strong>text</strong>
        
        // Optionally, you can apply other common replacements here for keywords if needed
        message = message.replace(/\n/g, '<br />'); // Replace newlines with <br> tags for HTML rendering

        return message; // Return the formatted message
    } catch (error) {
      console.error("Error fetching response from PHP:", error);
      return "Sorry, I couldn't get a response from the server."; // Fallback response in case of error
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  const toggleFavorite = (index) => {
    const updatedMessages = messages.map((msg, i) => i === index ? { ...msg, favorite: !msg.favorite } : msg);
    setMessages(updatedMessages);
  };

  const handleClearChat = () => {
    setMessages([]);
  };

  const toggleChatVisibility = () => {
    setIsHovered(false)
    setIsChatOpen(!isChatOpen);
  };

  useEffect(() => {
    // Scroll to the bottom of the chatbox whenever messages change
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div>
      {!isChatOpen && (
        <div onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)} >
        <div className="chatbot-circle" onClick={toggleChatVisibility}>
          <img src={profileImage1} alt="Profile" className="profile-photo" style={{ width: 70, height: 70, borderRadius: '50%' }} />
        </div>
        {isHovered && (
        <div className="hover-content chatbot-hover">
          <div className="message-element">
            <div className="message-container">
              <img
                src="https://routenotetest.com/rn22/use_luna_image/iconai-sparkle.svg"
                alt="AI Sparkle"
                className="message-icon"
                style={{ width: '20px', marginRight: '8px' }} // Adjust size and spacing
              />
              <div className="message-text-primary">Ask AI Analyst</div>
            </div>
            <div className="message-text-secondary">
              Hi! I'm AI Analyst. Click to generate charts and analyze your data effortlessly.
            </div>
          </div>
        </div>
        )}
      </div>
      
      )}

      {isChatOpen && (
        <div className="chatbot-container open">
          <div className="chatbot-header">
            <div className="profile-info">
              <img src={profileImage} alt="Profile" className="profile-photo" style={{ width: 40, height: 40, borderRadius: '50%' }} />
              <span className="title">Luna Executive AI</span>
            </div>
            <div>
            <Tooltip title="Build Chart by AI Analyst">
            </Tooltip>
              <RefreshIcon title="Clear Chat" className="clear-icon" style={{ cursor: 'pointer', width: '2em' }} onClick={handleClearChat} />
              <ExpandLessIcon title="Minimize Chat" className="minimize-icon" style={{ cursor: 'pointer' }} onClick={toggleChatVisibility} />
            </div>
          </div>

          <div className="chatbox" ref={chatBoxRef}>
            <div>
              <span style={{ fontSize: '15px', color: '#1345ac', fontWeight: 'bold' }}>👋 Welcome to Luna Executive AI  !</span><br /><br />
              <span style={{ color: '#1345ac' }}>
                Hello there! I'm Luna, your Executive AI Analyst, here to provide you with insightful analytics about your music streams, while also detecting fraud and identifying any suspicious bot activity. Whether you're looking to track song performance, gain audience insights, or ensure the integrity of your streams, I’m here to assist you!
              </span><br /><br />
            </div>

            {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender === 'bot' ? 'bot-message' : 'user-message'}`}>
                <div className="message-content" dangerouslySetInnerHTML={{ __html: message.text }} />
                <div className={`message-meta${message.sender === 'bot' ? '-bot' : '-user'}`}>
                {/* Uncomment if you want to use the favorite feature
                {message.favorite ? (
                    <StarIcon className="favorite-icon" style={{ cursor: 'pointer', color: 'gold' }} onClick={() => toggleFavorite(index)} />
                ) : (
                    <StarBorderIcon className="favorite-icon" style={{ cursor: 'pointer' }} onClick={() => toggleFavorite(index)} />
                )} */}
                </div>
            </div>
            ))}
            {loading && (
              <div className="message bot-message">
                <div className="message-thinking">...</div>
              </div>
            )}
          </div>

          <div className="input-container">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Ask something..."
            />
            <SendIcon 
              className="send-icon" 
              style={{ 
                cursor: 'pointer', 
                fontSize: 24, 
                color: input.trim() ? '#0056b3' : '#ccc', // Change color based on input
                pointerEvents: input.trim() ? 'auto' : 'none' // Enable/disable pointer events
              }} 
              onClick={handleSend} 
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
