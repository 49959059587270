import React from 'react';
import { Typography, Box } from '@mui/material';

const ExecutiveDashboard = () => {
  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Executive Dashboard
      </Typography>
      <Typography variant="body1">
        Welcome to the Executive Dashboard. Here you can view important metrics and make strategic decisions.
      </Typography>
      {/* Add your executive dashboard content here */}
    </Box>
  );
};

export default ExecutiveDashboard;