import React from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import classnames from "classnames";

// Styles import
import useStyles from "./styles";

// Components
import Header from "../Header";
import Sidebar from "../Sidebar";

// Pages
import Dashboard from "../../pages/dashboard";
import Dashlet from "../../pages/dashlet";
import AIAnalyst from "../../pages/dashlet/aianalyst";
import ExecutiveDashboard from "../../pages/dashboard/ExecutiveDashboard/ExecutiveDashboard.js";
import IndicatorDashboard from "../../pages/dashboard/IndicatorDashboard/IndicatorDashboard.js";

// Context
import { useLayoutState } from "../../context/LayoutContext";

function Layout() {
  const classes = useStyles();
  const history = useNavigate();
  const location = useLocation();

  const isDashboardRoute = location.pathname === '/app/dashboard';
  const isExecutiveRoute = location.pathname.includes('/app/executive-dashboard');
  const isIndicatorRoute = location.pathname.includes('/app/indicator-dashboard');

  // Global state
  const layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        {!isDashboardRoute && <Sidebar />}
        <Header history={history} showMenuIcon={!isDashboardRoute} />

        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened && !isDashboardRoute,
          })}
        >
          <div className={classes.fakeToolbar} />
          {isDashboardRoute && <Dashboard />}
          {isExecutiveRoute && <ExecutiveDashboard />}
          {isIndicatorRoute && <IndicatorDashboard />}
        </div>
      </>
    </div>
  );
}

export default Layout;
