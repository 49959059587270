import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import Chatbottest from "../../dashlet/chatbotTest.js"; 

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.common.black,
  fontWeight: 'bold',
}));

const generateRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

// Array of common country codes
const countryCodes = ['US', 'ID', 'BR', 'DE', 'PH', 'FR', 'IN', 'CN', 'JP', 'RU', 'AU', 'GB', 'CA'];

const getRandomCountryCode = () => countryCodes[Math.floor(Math.random() * countryCodes.length)];

const generateRandomTrackTitle = () => {
  const trackTitles = [
    "I'm Only a Fool for You",
    "Dancing in the Moonlight",
    "Shadows of the Past",
    "Lost in the Echoes",
    "Journey to the Unknown",
    "Whispers in the Wind",
  ];
  return trackTitles[Math.floor(Math.random() * trackTitles.length)];
};

const generateRandomArtistName = () => {
  const artistNames = [
    "Dybbukk Covers",
    "Echo Breakers",
    "Moonlight Trio",
    "Phantom Orchestra",
    "Silent Beats",
    "Skyline Symphony",
  ];
  return artistNames[Math.floor(Math.random() * artistNames.length)];
};


const generateRandomData = (numRecords) => {
  let data = [];
  for (let i = 0; i < numRecords; i++) {
    data.push({
        tracktitle: generateRandomTrackTitle(),
        artistName: generateRandomArtistName(),
        isrc: `USRC${generateRandomNumber(10000000, 99999999)}`,
        totalstreams: generateRandomNumber(100000, 5000000),
        totallisteners: generateRandomNumber(50000, 2000000),
        playlistadds: generateRandomNumber(10000, 500000),
        saves: generateRandomNumber(5000, 200000).toString(),
        uid: generateRandomNumber(1000000, 9999999).toString(),
        upc: generateRandomNumber(1000000000000, 9999999999999).toString(),
        country1: getRandomCountryCode(),
        streams1: generateRandomNumber(10000, 800000).toString(),
        country2: getRandomCountryCode(),
        streams2: generateRandomNumber(1000, 50000).toString(),
        country3: getRandomCountryCode(),
        streams3: generateRandomNumber(1000, 50000).toString(),
        country4: getRandomCountryCode(),
        streams4: generateRandomNumber(1000, 50000).toString(),
        country5: getRandomCountryCode(),
        streams5: generateRandomNumber(1000, 50000).toString(),
    });
  }
  return data;
};

const StaticMUITableComponent = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [data, setData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    const randomData = generateRandomData(1000); // Generate 1000 random records
    setData(randomData);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 7));
    setPage(0);
  };

  const columns = [
    { header: "Track Title", key: "tracktitle" },
    { header: "Artist Name", key: "artistName" },
    { header: "ISRC", key: "isrc" },
    { header: "Total Streams", key: "totalstreams" },
    { header: "Total Listeners", key: "totallisteners" },
    { header: "Playlist Adds", key: "playlistadds" },
    { header: "Saves", key: "saves" },
    { header: "UID", key: "uid" },
    { header: "UPC", key: "upc" },
    { header: "Country 1", key: "country1" },
    { header: "Streams 1", key: "streams1" },
    { header: "Country 2", key: "country2" },
    { header: "Streams 2", key: "streams2" },
    { header: "Country 3", key: "country3" },
    { header: "Streams 3", key: "streams3" },
    { header: "Country 4", key: "country4" },
    { header: "Streams 4", key: "streams4" },
    { header: "Country 5", key: "country5" },
    { header: "Streams 5", key: "streams5" }
  ];

  return (
    <Card>
      <CardHeader 
        title={
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h6">Fraud Score Report</Typography>
            </Grid>
          
          </Grid>
        }
      />
      <CardContent>
        <TableContainer component={Paper}>
          <Table >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableHeadCell key={column.key}>
                    {column.header}
                  </StyledTableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell>{row.tracktitle}</TableCell>        
                          <TableCell>{row.artistName}</TableCell>        
                          <TableCell>{row.isrc}</TableCell>              
                          <TableCell>{row.totalstreams}</TableCell>      
                          <TableCell>{row.totallisteners}</TableCell>    
                          <TableCell>{row.playlistadds}</TableCell>      
                          <TableCell>{row.saves}</TableCell>             
                          <TableCell>{row.uid}</TableCell>              
                          <TableCell>{row.upc}</TableCell>               
                          <TableCell>{row.country1}</TableCell>          
                          <TableCell>{row.streams1}</TableCell>          
                          <TableCell>{row.country2}</TableCell>          
                          <TableCell>{row.streams2}</TableCell>          
                          <TableCell>{row.country3}</TableCell>         
                          <TableCell>{row.streams3}</TableCell>          
                          <TableCell>{row.country4}</TableCell>          
                          <TableCell>{row.streams4}</TableCell>          
                          <TableCell>{row.country5}</TableCell>          
                          <TableCell>{row.streams5}</TableCell>          
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[7, 10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardContent>
      <Chatbottest />
    </Card>
     
  );
};

export default StaticMUITableComponent;